//font variables
$sans: 'Open Sans', sans-serif;
$raleway: 'Raleway', sans-serif;
$lora: 'Lora', serif;

// font weight
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// colors
$top-header:#c1d32b;
$navbar-green:#4d9536;
$body-green:#97bf42;
$navbar-hover:#96c042;
$black:#3d5643;
$text-green:#396d29;

//var
$tile-height: 232px;
$tile-wrapper: 850px;

//mixin
$tablet-width: 995px;
$desktop-width: 992px;
$breakpoint-alpha: 480px; // adjust to your needs

//bootrap
$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;

$screen-xs-max:              ($screen-sm - 1) !default;
$screen-xs-min:              ($screen-xs + 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
