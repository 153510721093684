@import 'components/_vars.scss';

@media (max-width: 970px) {
    section.welcome .tile-wrapper {
        height:auto;

        .tile {
            width: 50%;
            height: auto;
        }
    }
    .what-thumb {
        height:auto;
    }

    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
    .navbar-default .navbar-nav > li > a {
        padding-top:15px;
        padding-bottom:15px;

        &:before {
            display: none;
        }
    }

    .the-brand {
        width: calc(95% / 3);
    }

}

@media (max-width: 970px) {
    .inner-page {
        &:before , &:after {
            width:100%;
        }
    }
}

@media (max-width: $screen-xs) {
    @import 'media/_smaller-mobile';
    .the-brand {
        width: 100%;
    }
}

@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    @import 'media/_smaller-mobile-landscape';
    .the-brand {
        width: 100%;
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
    @import 'media/_mobile';
}

//container 750px
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @import 'media/_tablet';
}
@media (min-width: $screen-md) {

}

@media (min-width: $screen-lg) {

}
