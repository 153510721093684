.about-video {
    .thumb {
        display: block;
        text-align:center;
    }
    .caption {
        display: block;
        margin:0;
    }

}
