@import '../components/_vars';

.about-video {
    .thumb {
        display: block;
        text-align:center;
    }
    .caption {
        display: block;
        margin:0;
        text-align:center;
    }

}

.owl-theme .owl-nav {top:40%;}

section.welcome .tile-wrapper {
    height: auto;
    overflow:hidden;

    .tile {
        width:100%;
        height:auto;
    }
}

h1 {
    font-size:30px;
}
h2 {
    font-size:25px;
}
h3 {
    font-size:22px;
}
h4 {
    font-size:20px;
}
h5 {
    font-size:18px;
}
p, body , .contact-us{
    font-size:14px !important;
}

.form-horizontal {
    .control-label {
        &:before {
            display: none;
        }
    }
}
